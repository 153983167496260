import React from 'react';
import PropTypes from 'prop-types';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import NavItem from './NavItem';
import NavCollapse from './NavCollapse';
import { useGetMenuMaster } from '../../../../Menu';

export default function NavGroup({ item }) {
  const { menuMaster } = useGetMenuMaster();
  const drawerOpen = menuMaster.isDashboardDrawerOpened;

  const [openCollapse, setOpenCollapse] = React.useState({});

  const handleToggle = (id) => {
    setOpenCollapse((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const navCollapse = item.children?.map((menuItem) => {
    switch (menuItem.type) {
      case 'collapse':
        return (
          <Box key={menuItem.id}>
            <NavCollapse
              item={menuItem}
              level={1}
              onClick={() => handleToggle(menuItem.id)}
            />
            <Collapse in={openCollapse[menuItem.id]}>
              <List>
                {menuItem.children.map((subItem) => (
                  <NavItem key={subItem.id} item={subItem} level={1} />
                ))}
              </List>
            </Collapse>
          </Box>
        );
      case 'item':
        return <NavItem key={menuItem.id} item={menuItem} level={1} />;
      default:
        return (
          <Typography key={menuItem.id} variant="h6" color="error" align="center">
            Fix - Group Collapse or Items
          </Typography>
        );
    }
  });

  return (
    <List
      subheader={
        item.title && (
          <Box sx={{ pl: 2}}>
            <Typography variant="subtitle2" color="textSecondary">
              {item.title}
            </Typography>
          </Box>
        )
      }
      sx={{ mb: 1.5, py: 0 }}
    >
      {navCollapse}
    </List>
  );
}

NavGroup.propTypes = { item: PropTypes.object.isRequired };
