import React, { useState, useEffect } from 'react';
import { useMediaQuery, Button, Snackbar, Typography } from '@mui/material';

const InstallPwaPrompt = () => {
  const [showInstallMessage, setShowInstallMessage] = useState(false);
  const isSmallScreen = useMediaQuery('(max-width: 768px)');
  let deferredPrompt;

  useEffect(() => {
    // Check if running in standalone mode
    const isPWA = window.matchMedia('(display-mode: standalone)').matches;
    if (isPWA) return; // Do not show the prompt if already installed

    // Listen for the beforeinstallprompt event
    const handleBeforeInstallPrompt = (e) => {
      e.preventDefault();
      deferredPrompt = e;
      if (isSmallScreen) {
        setShowInstallMessage(true); // Show the install prompt on small screens
      }
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, [isSmallScreen]);

  const handleInstallClick = async () => {
    if (deferredPrompt) {
      deferredPrompt.prompt(); // Show the native install prompt
      const choiceResult = await deferredPrompt.userChoice;
      if (choiceResult.outcome === 'accepted') {
        console.log('User accepted the PWA installation');
      } else {
        console.log('User dismissed the PWA installation');
      }
      deferredPrompt = null;
      setShowInstallMessage(false);
    }
  };

  return (
    <>
      {showInstallMessage && (
        <Snackbar
          open={showInstallMessage}
          sx={{
            '& .MuiSnackbarContent-root': {
              backgroundColor: 'primary.dark',  
              color: '#ffffff',              
            },
          }}
          message={
            <Typography>
              Install the app for a better experience!
            </Typography>
          }
          action={
            <Button color="primary" variant='contained' onClick={handleInstallClick} sx={{height:'30px'}}>
              Install
            </Button>
          }
          onClose={() => setShowInstallMessage(false)}
        />
      )}
    </>
  );
};

export default InstallPwaPrompt;
