import { useState, useEffect } from 'react';

// Custom hook to get a cookie value by name
const useCookie = (name) => {
  const [cookieValue, setCookieValue] = useState('');

  useEffect(() => {
    const getCookie = (name) => {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop().split(';').shift();
      return '';
    };

    setCookieValue(getCookie(name));
  }, [name]);

  return cookieValue;
};

export default useCookie;