import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import {jwtDecode} from 'jwt-decode';

const AuthGuard = (WrappedComponent) => {

  const GuardedComponent = (props) => {
    const navigate = useNavigate();

    useEffect(() => {
      const token = Cookies.get('_athtxn');

      if (!token) {
        navigate('/login');
      } else {
        try {
          const decoded = jwtDecode(token);
          const currentTime = Date.now() / 1000;
          if (decoded.exp < currentTime) {
            Cookies.remove('token');
            navigate('/login');
          }
        } catch (error) {
          navigate('/login');
        }
      }
    }, [navigate]);

    return <WrappedComponent {...props} />;
  };

  return GuardedComponent; // Return the wrapped component
};

export default AuthGuard;