import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Rating from '@mui/material/Rating';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import useCookie from '../utils/getCookie';

const FeedbackDialog = ({ open, onClose }) => {
  const [rating, setRating] = useState(null);
  const [thoughts, setThoughts] = useState('');
  const [followUp, setFollowUp] = useState('1');

  const token = useCookie('_athtxn');

  const handleSubmit = async () => {
    try {
      const response = await fetch('https://api.joblixx.com/v1/user/feedback', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': process.env.REACT_APP_CSRF_TOKEN,
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
          rating,
          thoughts,
          followUp,
        }),
      });

      if (response.ok) {
        // Handle successful submission
        onClose();
      } else {
        // Handle error
        console.error('Failed to submit feedback');
      }
    } catch (error) {
      console.error('Error submitting feedback:', error);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        Give feedback
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{ position: 'absolute', right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography variant="h6" gutterBottom>
          What do you think of Joblixx?
        </Typography>
        <Rating
          name="feedback-rating"
          size='large'
          value={rating}
          onChange={(event, newValue) => setRating(newValue)}
        />
        <TextField
          fullWidth
          multiline
          rows={4}
          margin="normal"
          label="Do you have any thoughts you'd like to share?"
          value={thoughts}
          onChange={(e) => setThoughts(e.target.value)}
        />
        <Typography variant="subtitle1" gutterBottom>
          May we follow up on your feedback?
        </Typography>
        <RadioGroup
          row
          value={followUp}
          onChange={(e) => setFollowUp(e.target.value)}
        >
          <FormControlLabel value='1' control={<Radio />} label="Yes" />
          <FormControlLabel value='0' control={<Radio />} label="No" />
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSubmit} variant="contained" color="primary">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

// You need to implement these functions to provide the necessary security tokens
function getCsrfToken() {
  // Implement CSRF token retrieval logic
  return 'your-csrf-token';
}

function getJwtToken() {
  // Implement JWT token retrieval logic
  return 'your-jwt-token';
}

export default FeedbackDialog;