import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import {jwtDecode} from 'jwt-decode';

const PublicGuard = (WrappedComponent) => {
  
  const GuardedComponent = (props) => {
    const navigate = useNavigate(); 

    useEffect(() => {
        const token = Cookies.get('_athtxn');
      
        if (token) {
          try {
            const decoded = jwtDecode(token);
            const currentTime = Date.now() / 1000; 
      
            if (decoded.exp > currentTime) {
              // Token is valid and not expired; redirect the user to the home page
              navigate('/home');
            } else {
              console.warn('Token is expired. User will stay on the current page.');
            }
          } catch (error) {
            console.error('Token decoding failed:', error);
          }
        } else {
          console.info('No token found. User will stay on the current page.');
        }
      }, [navigate]);   

    return <WrappedComponent {...props} />;
  };

  return GuardedComponent; // Return the wrapped component
};

export default PublicGuard;
