import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';

const useLogout = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    // Remove the token from cookies
    Cookies.remove('_athtxn');

    // Redirect to login page
    navigate('/login');
  };

  return handleLogout;
};

export default useLogout;
