import { lazy } from 'react';
import { createBrowserRouter } from 'react-router-dom';

// project import
import Loadable from './components/app/Loadable';
import Layout from './components/app/layout/Layout';
import AuthGuard from './utils/AuthGuard';
import PublicGuard from './utils/PublicGuard';

// App Routes
const Dashboard = AuthGuard(Loadable(lazy(() => import('./pages/app/Overview'))));
const EditProfile = AuthGuard(Loadable(lazy(() => import('./pages/app/EditProfile'))));
const Preferences = AuthGuard(Loadable(lazy(() => import('./pages/app/Preferences'))));
const Checkout = AuthGuard(Loadable(lazy(() => import('./pages/app/Checkout'))));
const Scheduler = AuthGuard(Loadable(lazy(() => import('./pages/app/Scheduler'))));
const Apply = AuthGuard(Loadable(lazy(() => import('./pages/app/Apply'))));
const JobDetailPage = AuthGuard(Loadable(lazy(() => import('./components/app/apply/JobDetailPage'))));
const Plans = AuthGuard(Loadable(lazy(() => import('./pages/app/Plans'))));
const Settings = AuthGuard(Loadable(lazy(() => import('./pages/app/Settings'))));
const JobTracker = AuthGuard(Loadable(lazy(() => import('./pages/app/JobTracker'))));

// Auth Routes
const InitialInfo = PublicGuard(Loadable(lazy(() => import('./pages/account/InitialInfo'))));
const Signup = PublicGuard(Loadable(lazy(() => import('./pages/account/SignUp'))));
const Signin = PublicGuard(Loadable(lazy(() => import('./pages/account/LogIn'))));
const Forgot = PublicGuard(Loadable(lazy(() => import('./pages/account/ResetPassword'))));
const ChangePassword = PublicGuard(Loadable(lazy(() => import('./pages/account/ChangePassword'))));

// Initial Info Routes
const CareerStage = PublicGuard(Loadable(lazy(() => import('./pages/account/initial-info/CareerStage'))));
const Titles = PublicGuard(Loadable(lazy(() => import('./pages/account/initial-info/Titles'))));
const Skill = PublicGuard(Loadable(lazy(() => import('./pages/account/initial-info/Skills'))));
const Location = PublicGuard(Loadable(lazy(() => import('./pages/account/initial-info/Location'))));
const SearchStage = PublicGuard(Loadable(lazy(() => import('./pages/account/initial-info/SearchStage'))));
const Language = PublicGuard(Loadable(lazy(() => import('./pages/account/initial-info/Languages'))));
const LinkedIn = PublicGuard(Loadable(lazy(() => import('./pages/account/initial-info/LinkedIn'))));
const SignupSource = PublicGuard(Loadable(lazy(() => import('./pages/account/initial-info/SignupSource'))));

// Public routes
const UserResume = Loadable(lazy(() => import('./components/app/profile/resume/UserResume')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  element: <Layout />,
  children: [
    {
      path: '/',
      element: <Dashboard />
    },
    {
      path: 'home',
      element: <Dashboard />,
      children: [
        {
          path: 'default',
          element: <Dashboard />
        }
      ]
    },
    {
      path: 'profile',
      element: <EditProfile />
    },
    {
      path: 'preferences',
      element: <Preferences />
    },
    {
      path: 'scheduler',
      element: <Scheduler />
    },
    {
      path: 'apply',
      element: <Apply />
    },
    {
      path: 'job/:jobId/:jobName',
      element: <JobDetailPage />
    },
    {
      path: 'plans',
      element: <Plans />
    },
    {
      path: 'settings',
      element: <Settings />
    },
    {
      path: 'job-tracker',
      element: <JobTracker />
    },
  ]
};

const AuthRoutes = {
  path: '/',
  children : [
    {
      path: 'register',
      element: <Signup />
    },
    {
      path: 'login',
      element: <Signin />
    },
    {
      path: 'forgot',
      element: <Forgot />
    },
    {
      path: 'change-password',
      element: <ChangePassword />
    },
    {
      path: 'initial-info',
      element: <InitialInfo />
    },
  ]
};

const IIroute = {
  path: '/initial-info',
  children : [
    {
      path: 'career-stage',
      element: <CareerStage />
    },
    {
      path: 'titles',
      element: <Titles />
    },
    {
      path: 'skills',
      element: <Skill />
    },
    {
      path: 'location',
      element: <Location />
    },
    {
      path: 'search-stage',
      element: <SearchStage />
    },
    // {
    //   path: 'language',
    //   element: <Language />
    // },
    // {
    //   path: 'linked-in',
    //   element: <LinkedIn />
    // },
    {
      path: 'signup-source',
      element: <SignupSource />
    },
  ]};

const Checkoutroute = {
    path: '/checkout',
    element:  <Checkout />
};

const Miscroute = {
  path: 'resume/:userName',
  element: <UserResume />
};

const router = createBrowserRouter([MainRoutes, Checkoutroute, AuthRoutes, IIroute, Miscroute], { basename: '/' });

export default router;