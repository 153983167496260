import React from 'react'; // Ensure React is imported
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

// project import
import NavGroup from './NavGroup';
import menuItems from '../../../../menu-items/mi-index';

// ==============================|| DRAWER CONTENT - NAVIGATION ||============================== //

export default function Navigation() {
  const navGroups = menuItems.items.map((item) => {
    switch (item.type) {
      case 'group':
        return <NavGroup key={item.id} item={item} />;
      default:
        return (
          <Typography key={item.id} variant="body2" color="error" align="center">
            Fix - Navigation Group
          </Typography>
        );
    }
  });

  return (
    <Box sx={{ pt: 2 }}>
      {navGroups.length > 0 ? navGroups : <Typography variant="body2" color="textSecondary" align="center">No Navigation Items</Typography>}
    </Box>
  );
}
