import PropTypes from 'prop-types';
import { forwardRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

// project import
import { handlerActiveItem, useGetMenuMaster } from '../../../../Menu';

const NavCollapse = ({ item, level, onClick }) => {
  const theme = useTheme();
  const { menuMaster } = useGetMenuMaster();
  const drawerOpen = menuMaster.isDashboardDrawerOpened;
  const openItem = menuMaster.openedItem;

  const Icon = item.icon;
  const itemIcon = item.icon ? <Icon style={{ fontSize: drawerOpen ? '1rem' : '1.25rem' }} /> : null;

  const isSelected = openItem === item.id;

  // active menu item on page load
  useEffect(() => {
    if (isSelected) handlerActiveItem(item.id);
    // eslint-disable-next-line
  }, [isSelected]);

  const textColor = 'text.primary';
  const iconSelectedColor = 'primary.main';

  return (
    <ListItemButton
      onClick={onClick}
      selected={isSelected}
      sx={{
        zIndex: 1201,
        pl: drawerOpen ? `${level * 28}px` : 1.5,
        py: !drawerOpen && level === 1 ? 1.25 : 1,
        ...(drawerOpen && {
          '&:hover': {
            bgcolor: 'primary.lighter',
          },
          '&.Mui-selected': {
            bgcolor: 'primary.lighter',
            // borderRight: `2px solid ${theme.palette.primary.main}`,
            borderRadius: '10px',
            // margin: '5px',
            color: iconSelectedColor,
            '&:hover': {
              color: iconSelectedColor,
              bgcolor: 'primary.lighter',
            },
          },
        }),
        ...(!drawerOpen && {
          '&:hover': {
            bgcolor: 'primary.lighter',
          },
          '&.Mui-selected': {
            '&:hover': {
              bgcolor: 'transparent',
            },
            bgcolor: 'transparent',
          },
        }),
      }}
    >
      {itemIcon && (
        <ListItemIcon
          sx={{
            minWidth: 28,
            color: isSelected ? iconSelectedColor : textColor,
            ...(!drawerOpen && {
              borderRadius: 1.5,
              width: 36,
              height: 36,
              alignItems: 'center',
              justifyContent: 'center',
              '&:hover': {
                bgcolor: 'primary.lighter',
              },
            }),
            ...(!drawerOpen && isSelected && {
              bgcolor: 'primary.lighter',
              '&:hover': {
                bgcolor: 'primary.lighter',
              },
            }),
          }}
        >
          {itemIcon}
        </ListItemIcon>
      )}
      <ListItemText
        primary={
          <Typography variant="body2" sx={{ color: isSelected ? iconSelectedColor : textColor }}>
            {item.title}
          </Typography>
        }
      />
      <IconButton
        sx={{
          ml: 'auto',
          color: isSelected ? iconSelectedColor : textColor,
          ...(drawerOpen && {
            '&:hover': {
              bgcolor: 'primary.lighter',
            },
          }),
        }}
      >
        {openItem === item.id ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </IconButton>
    </ListItemButton>
  );
};

NavCollapse.propTypes = { 
  item: PropTypes.object.isRequired, 
  level: PropTypes.number.isRequired, 
  onClick: PropTypes.func
};

export default NavCollapse;
