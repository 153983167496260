import { RouterProvider } from 'react-router-dom';
import React from 'react';

// project import
import router from './AppRoutes';
import ThemeCustomization from './themes';

// ==============================|| APP - THEME, ROUTER, LOCAL ||============================== //

export default function App() {
  return (
    <ThemeCustomization>
        <RouterProvider router={router} />
    </ThemeCustomization>
  );
}