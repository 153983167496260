// material-ui
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import { Tooltip, IconButton } from '@mui/material';
import { QuestionCircleOutlined } from '@ant-design/icons';

// project import
import Profile from './Profile/Profile';
import Notification from './Notification';
import MobileSection from './MobileSection';

// ==============================|| HEADER - CONTENT ||============================== //

export default function HeaderContent() {
  const downLG = useMediaQuery((theme) => theme.breakpoints.down('lg'));

  return (
    <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
      {downLG && <Box sx={{ width: 'auto', ml: 1 }} />}
      <Tooltip title='Support'>
      <IconButton 
        color="secondary"
        variant="light"
        sx={{ color: 'text.primary', fontSize: 'large' }}
        onClick={() => window.open('https://www.joblixx.com/help-center', '_blank')}
      ><QuestionCircleOutlined />
      </IconButton>
      </Tooltip>
      {/* <Notification /> */}
      {!downLG && <Profile />}
      {downLG && <MobileSection />}
    </Box>
  );
}
