import Navigation from './Navigation/Navigation';
import SimpleBarScroll from '../../../SimpleBar';
import { Box, Button, Stack, Typography } from '@mui/material';
import { StarFilled  } from '@ant-design/icons';

// ==============================|| DRAWER CONTENT ||============================== //

export default function DrawerContent() {
  return (
    <>
      <SimpleBarScroll sx={{'& .simplebar-content': { display: 'flex', flexDirection: 'column' } }}>
        <Navigation />
      </SimpleBarScroll>
      <Stack alignContent='column'>
      <Box 
        sx={{border:1, 
            borderRadius:2, 
            borderColor:'divider', 
            p:1, 
            margin:1,
            background: 'linear-gradient(70deg, #1f6c93, #0d2e3f)'
            }}>
        <Typography variant='h5' 
          fontWeight='800'
          fontSize='clamp(1rem, 3vw, 1.3rem)'
          sx={{ color:'primary.light'}}>
          Become a <br></br> Seeker
        </Typography>
        <Typography variant='body2' 
          sx={{mb:2,  color:'primary.light',
            fontSize:'clamp(0.7rem, 3vw, 0.8rem)'
          }}>
          Match with top jobs <br></br>alined with your profile
        </Typography>
        <Button
          variant='contained'
          href='/plans'
          size='medium'
          sx={{ p: 2}} 
          startIcon={<StarFilled style={{ color: '#FFD700' }} />}
        >
        <b>Upgrade Plan</b>
        </Button>
      </Box>
      </Stack>
    </>
  );
}
