// assets
import { DashboardOutlined, UserOutlined, ScheduleOutlined, SendOutlined, IdcardOutlined} from '@ant-design/icons';

// icons
const icons = {
  DashboardOutlined, UserOutlined, ScheduleOutlined, SendOutlined, IdcardOutlined
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const dashboard = {
  id: 'group-dashboard',
  // title: 'Navigation',
  type: 'group',
  children: [
    {
      id: 'overview',
      title: 'Overview',
      type: 'item',
      url: '/home',
      icon: icons.DashboardOutlined,
      breadcrumbs: false
    },
    {
      id: 'apply',
      title: 'Apply',
      type: 'item',
      url: '/apply',
      icon: icons.SendOutlined,
      breadcrumbs: true
    },
    {
      id: 'scheduler',
      title: 'Scheduler',
      type: 'item',
      url: '/scheduler',
      icon: icons.ScheduleOutlined,
      breadcrumbs: true,
    },
    // {
    //   id: 'profile',
    //   title: 'Profile',
    //   type: 'collapse',
    //   icon: icons.UserOutlined,
    //   breadcrumbs: true,
    //   children: [p
    //   ]
    // },
    {
      id: 'prefrences',
      title: 'Prefrences',
      type: 'item',
      url: '/preferences',
      icon: icons.IdcardOutlined,
      breadcrumbs: true
    },
    {
      id: 'profile',
      title: 'Profile',
      type: 'item',
      url: '/profile',
      icon: icons.UserOutlined,
      breadcrumbs: true
    },
  ]
};

export default dashboard;