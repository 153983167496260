import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

// material-ui
import { useMediaQuery, ThemeProvider } from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import { createTheme } from '@mui/material/styles';

// project import
import MainDrawer from './Drawer/Drawer';
import Header from './Header/Header';
import menuItems from '../menu-items/mi-index';
import Loader from '../Loader';
import Breadcrumbs from '../Breadcrumbs';
import getLPTheme from '../../../getLPTheme';
import InstallPwaPrompt from '../../../utils/PwaInstaller';

import { handlerDrawerOpen, useGetMenuMaster } from '../Menu';

// ==============================|| MAIN LAYOUT ||============================== //

export default function DashboardLayout() {
  const { menuMasterLoading } = useGetMenuMaster();
  const theme = createTheme(getLPTheme('light'));
  const downXL = useMediaQuery(theme.breakpoints.down('lg'));

  useEffect(() => {
    handlerDrawerOpen(!downXL);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [downXL]);

  if (menuMasterLoading) return <Loader />;

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex', width: '100%' }}>
        <Header />
        <MainDrawer />
        <Box component="main" sx={{ width: 'calc(100% - 260px)', flexGrow: 1, p: { xs: 2 }, backgroundColor:'background.default' }} >
          <Toolbar />
          <InstallPwaPrompt />
          <Breadcrumbs navigation={menuItems} title />
          <Outlet />
        </Box>
      </Box>
    </ThemeProvider>
  );
}
