import { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import {jwtDecode} from 'jwt-decode';

const useTokenData = () => {
  const [tokenData, setTokenData] = useState(null);

  useEffect(() => {
    const token = Cookies.get('_athtxn');

    if (token) {
      try {
        const decoded = jwtDecode(token);
        setTokenData(decoded);
      } catch (error) {
        console.error("Invalid token", error);
        setTokenData(null);
      }
    }
  }, []);

  return tokenData;
};

export default useTokenData;
