import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';

// project import
import getLPTheme from '../../../../../getLPTheme';
import DrawerHeaderStyled from './DrawerHeaderStyled';
import SitemarkIcon from '../../../../brand/SitemarkIcon';

// ==============================|| DRAWER HEADER ||============================== //

export default function DrawerHeader({ open }) {
  const theme = useTheme(getLPTheme);

  return (
    <DrawerHeaderStyled theme={theme} open={!!open}>
      <SitemarkIcon isIcon={!open} sx={{ width: open ? 'auto' : 35 }} />
    </DrawerHeaderStyled>
  );
}

DrawerHeader.propTypes = { open: PropTypes.bool };