import React, { useState } from 'react';

// material-ui
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';

// assets
import { CommentOutlined, LockOutlined, QuestionCircleOutlined, UnorderedListOutlined } from '@ant-design/icons';

// components
import FeedbackDialog from '../../../../../Feedback';

const SettingTab = () => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [feedbackDialogOpen, setFeedbackDialogOpen] = useState(false);

  const tabUrls = {
    0: '/settings',
    1: 'https://www.joblixx.com/privacy-policy',
    3: 'https://blog.joblixx.com'
  };

  const handleListItemClick = (index) => {
    if (index === 2) {
      setFeedbackDialogOpen(true);
    } else if (tabUrls.hasOwnProperty(index)) {
      setSelectedIndex(index);
      window.location.href = tabUrls[index];
    } else {
      console.error(`No URL found for index: ${index}`);
    }
  };

  return (
    <>
      <List component="nav" sx={{ p: 0, '& .MuiListItemIcon-root': { minWidth: 32 } }}>
        <ListItemButton selected={selectedIndex === 0} onClick={() => handleListItemClick(0)}>
          <ListItemIcon>
            <ManageAccountsOutlinedIcon style={{fontSize: 'large'}}/>
          </ListItemIcon>
          <ListItemText primary="Account Settings" />
        </ListItemButton>
        <ListItemButton selected={selectedIndex === 1} onClick={() => handleListItemClick(1)}>
          <ListItemIcon>
            <LockOutlined style={{fontSize: 'medium'}}/>
          </ListItemIcon>
          <ListItemText primary="Privacy Center" />
        </ListItemButton>
        <ListItemButton selected={selectedIndex === 2} onClick={() => handleListItemClick(2)}>
          <ListItemIcon>
            <CommentOutlined style={{fontSize: 'medium'}}/>
          </ListItemIcon>
          <ListItemText primary="Feedback" />
        </ListItemButton>
        <ListItemButton selected={selectedIndex === 3} onClick={() => handleListItemClick(3)}>
          <ListItemIcon>
            <UnorderedListOutlined style={{fontSize: 'medium'}}/>
          </ListItemIcon>
          <ListItemText primary="Joblixx Blog" />
        </ListItemButton>
      </List>
      <FeedbackDialog 
        open={feedbackDialogOpen} 
        onClose={() => setFeedbackDialogOpen(false)} 
      />
    </>
  );
};

export default SettingTab;